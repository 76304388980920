import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, MonoTypeOperatorFunction, forkJoin, of } from 'rxjs';
import { map, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Member, MemberAddress, MemberBeneficiary, MemberBenefit, MemberDrop, MemberFiscal, MemberNotificationOptInsList } from '../models';
import { timedRetryStrategy } from './timedRetryStrategy';
import { VerifyBadgeResponse } from '../models/verify-badge-response';
import { VerifyBadgeRequest } from '../models/verify-badge-request';
import { AuthenticationRequest } from '../models/authentication-request';
import { AuthenticationResponse } from '../models/authentication-response';
import { PaymentHistoriesOfPayeeQueryModel } from 'src/app/pages/models';
import { SetNotificationOptInOptOut } from '../models/member-opt-in';
import { MemberInfo, MemberRaw } from '../models/member';
import { PaymentHistoryResponse, PaymentResponse } from '../models/payment-history-response';
import { PaymentHistoryRequest } from '../models/payment-history-request';

@Injectable({
    providedIn: 'root'
})
export class MemberService {
    retryStrategy:MonoTypeOperatorFunction<any> = retryWhen(timedRetryStrategy({
        timeoutDuration: environment.http.timeout,
        maxRetryAttempts: environment.http.retry,
        scalingDuration: 500
    }));

    constructor(private httpClient: HttpClient) { }

    public getMember(memberId: string) : Observable<MemberInfo> {
        return this.httpClient.get<Member>(`${environment.apiUrl}/Member/member-info?memberId=${memberId}`).pipe(this.retryStrategy);
    }

    public getMetadata(itemKey: string) : Observable<any> {
        return of({});
    }

    public getBenefit(memberId : string) : Observable<MemberBenefit> {
        return this.httpClient.get<MemberBenefit>(`${environment.apiUrl}/Member/Benefit`).pipe(this.retryStrategy);
    }

    public getDrop(memberId : string) : Observable<MemberDrop> {
        return this.httpClient.get<MemberDrop>(`${environment.apiUrl}/Member/DROP`).pipe(this.retryStrategy);
    }

    public getFiscal(memberId: string) : Observable<MemberFiscal> {
        return forkJoin({
            fiscal: this.httpClient.get<MemberFiscal>(`${environment.apiUrl}/Member/Fiscal`).pipe(this.retryStrategy),
            history: this.httpClient.get<any>(`${environment.apiUrl}/Member/FiscalHistory`).pipe(this.retryStrategy)
        }).pipe(map(({ fiscal, history }) => {
            fiscal.history = history.table;
            return fiscal;
        }));
    }
    
    public getMemberFixedPaymenthistories<TData = PaymentHistoriesOfPayeeQueryModel>(): Observable<TData>  {
        return this.httpClient.get<TData>(
          `${environment.apiUrl}/Member/fixed__paymenthistories`
        );
      }
    
    public getDataFollowBenefit(queryParams: any) {
        let params = new HttpParams();
        for (let p of Object.keys(queryParams)) {
            params = params.append(p, queryParams[p])
        }
        return this.httpClient.get<PaymentResponse>(
            `${environment.apiUrl}/Member/finalized-payment`,
            {params}
        );
    }

    public getBeneficiary(memberId: string) : Observable<MemberBeneficiary> {
        return this.httpClient.get<MemberBeneficiary>(`${environment.apiUrl}/Member/Beneficiary`).pipe(this.retryStrategy);
    }

    public getAddress(memberId: string) : Observable<MemberAddress> {
        return this.httpClient.get<MemberAddress>(`${environment.apiUrl}/Member/Demographics`).pipe(this.retryStrategy, map(req => req.address));
    }

    public verifyMember(verifyBadgeRequest: VerifyBadgeRequest): Observable<VerifyBadgeResponse> {
        return this.httpClient.post<VerifyBadgeResponse>(`${environment.apiUrl}/Auth0/VerifyBadge`, verifyBadgeRequest);
    }
    
    public authenticate(authRequest: AuthenticationRequest): Observable<AuthenticationResponse> {
        return this.httpClient.post<AuthenticationResponse>(`${environment.apiUrl}/Auth0/Authentication`, authRequest);
    }

    public getOptInMessage(clientId: string, memberId: string) : Observable<MemberNotificationOptInsList> {
        return this.httpClient.get<MemberNotificationOptInsList>(`${environment.apiUrl}/Member/NotificationOptIn/${clientId}/${memberId}`).pipe(this.retryStrategy);
    }

    public setOptInMessage(memberId: string, body: SetNotificationOptInOptOut) : Observable<any[]> {
        return this.httpClient.put(`${environment.apiUrl}/Member/NotificationOptIn/${memberId}`, body).pipe(this.retryStrategy);
    }

    public generatePaymentInfo(paymentHistoryRequest: PaymentHistoryRequest): Observable<PaymentHistoryResponse> {
        return this.httpClient.post<PaymentHistoryResponse>(`${environment.apiUrl}/Member/generate-payment-info`, paymentHistoryRequest);
    }
}